/*-------------------------------------------------------*\
    Base
/*-------------------------------------------------------*/

html {
  @include bgimagecover(transparent, '../img/bkg.jpg', center);
}

body {
  height: 100%;
  min-height: 100vh;
  background-color: transparent;
  background-image: linear-gradient(to left, rgba(#ded1ac, 0.6), rgba(#3d727a, 0.8));
}

#main {

  // padding: 5vh 0;
}

#main {
  min-height: 100vh;
  .container {
    min-height: 100vh;
    .row {
      min-height: 100vh;
      padding: 5vh 0;
    }
  }
}

.navbar-brand {
  color: white;
}

.row {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}
