/*-------------------------------------------------------*\
    Typography
/*-------------------------------------------------------*/

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: $base-font-family;
    font-weight: $base-font-weight;
    font-size: $base-font-size;
    line-height: $base-line-height;
    color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    line-height: 1.3;
}

p {
    margin: 0 0 17px;
}

.nav, .navbar {
    font-family: $header-font-family;
    font-weight: $regular;
}
