// /*-------------------------------------------------------*\
//     Navs
// /*-------------------------------------------------------*/

.navbar-inverse {
    background-color: rgba(#3d727a, 0.2);
}

.navbar-brand {
  padding-left: 15px;
}
