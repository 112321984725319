/* ==========================================================================
	State rules
	========================================================================== */

// State rules are ways to describe how our modules or layouts will look
// when in a particular state. Is it hidden or expanded? Is it active or
// inactive? They are about describing how a module or layout looks on
// screens that are smaller or bigger. They are also about describing how
// a module might look in different views like the home page or the inside
// page.
// --------------------------------------------------

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {
	html, body {
		font-size: 14px;
	}
}
