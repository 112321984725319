/*-------------------------------------------------------*\
    Links
/*-------------------------------------------------------*/

a,
a:hover,
button,
button:hover {
    @include transition(all 0.3s ease-in-out);
}

a {
    color: $text-link-color;
    text-decoration: none;
    &:hover {
        color: darken($text-link-color, 15%);
        text-decoration: none;
    }
}
