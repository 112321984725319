/*-------------------------------------------------------*\
    Variables
/*-------------------------------------------------------*/

//== Colors
//
//## Colors for use across site.

$text-color: white;
$text-color-inverse: #fff;
$text-link-color: #29a7df;
$border-color: #adadad;
$brand-color-1: #FFFFFF;
$brand-color-2: #F0F0F0;
$brand-color-3: #545454;
$brand-color-4:	#696969;
$brand-color-5: #666666;
$brand-color-6: #d3d3d3;
$brand-color-7: #a8a8a8;
$brand-color-8:	#ededed;
$accent-color: #d3d3d3;
$accent-color-2: #444444;

$white: #ffffff;
$black: #000000;
$red: #ff0000;
$darkblue: #324a57;

$blue: #29a7df;
$green: #45b941;
$purple: #7d397f;
$gray: #69737c;
$light-gray: #d8d8e2;

//== Scaffolding
//
//## Settings for some of the most global styles.


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

// Font weights
$light: 300;
$regular: 400;
$semibold: 600;
$bold: 700;

// Base Font
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-weight: $regular;
$base-font-size: 16px;
$base-line-height: 1.5;

// Headings
$header-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$header-font-weight: $bold;
