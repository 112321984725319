/*-------------------------------------------------------*\
    Cards
/*-------------------------------------------------------*/

.card {
	color: rgba(white, 0.9);
	background-color: rgba(#3d727a, 0.8);
	padding: 30px;
	.text-muted {
		color: rgba(white, 0.7) !important;
	}
	.form-group {
		margin: 20px 0 30px;
	}
	.btn-primary {
		color: #3d727a;
		background-color: rgba(white, 0.7);
		border: 2px solid rgba(white, 0.7);
		margin-top: 15px;
		&:hover,
		&:focus,
		&:active {
			color: rgba(white, 0.9);
			background-color: #3d727a;
		}
	}
}
