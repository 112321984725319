/*-------------------------------------------------------*\
    Heroes
/*-------------------------------------------------------*/

.homepage {
    &-hero {
        color: #fff;
        text-align: right;
        @include gradient-over-img($blue, $blue, 0.7, '../img/hero-bkg.jpg', center);

        .hero-message {
            //height: 500px;
            padding: 180px 15px 100px;
            //@include radial-gradient(rgba(#fff, 0.9), rgba(#fff, 0));
            /*background: rgba(255, 255, 255, 0);
            background: -webkit-radial-gradient(rgba(#fff, 0.8), rgba(#fff, 0.6) 40%, rgba(#fff, 0) 80%);
            background: -o-radial-gradient(rgba(#fff, 0.8), rgba(#fff, 0.6) 40%, rgba(#fff, 0) 80%);
            background: -moz-radial-gradient(rgba(#fff, 0.8), rgba(#fff, 0.6) 40%, rgba(#fff, 0) 80%);
            background: radial-gradient(rgba(#fff, 0.8), rgba(#fff, 0.6) 40%, rgba(#fff, 0) 80%);*/
        }
        h2 {
            font-size: 3.6em;
            font-weight: $light;
            line-height: 1.3;
            margin-top: 0;
        }
		a.phone-number {
			text-decoration: none;
			font-weight: $bold;
			font-size: 3em;
		}
        .btn {
            margin-top: 30px;
        }

        &.hero-2 {
            .hero-message {
                //padding-top: 110px;
            }
            .btn-label {
                text-align: center;
                width: 155px;
                margin-top: 20px;
                margin-bottom: 0;
                margin-left: auto;
            }
            .btn {
                width: 155px;
                padding: 10px;
                margin-top: 4px;
            }
        }
    }
}

.hero {
    &-tagline {
        color: $text-color;
        text-align: center;
        background-color: #f5f5fb;
        padding: 40px 0;
        h3 {
            font-weight: $semibold;
            margin-bottom: 0;
        }
    }
}

#body {
    .homepage-hero {
        a:not(.btn).phone-number {
            display: inline-block;
            font-size: 2.2em;
            font-weight: bold;
            border-bottom: none;
            padding-bottom: 0.5em;
        }
    }
}

.form-hero {
    padding-top: 70px;
    .container {
        height: 100%;
    }
    .row {
        height: 100%;
        align-items: center;
    }
    h2 {
        font-size: 3em;
        text-align: left;
    }
    .hero-message {
        padding: 0;
    }
    .btn {
        padding: 10px;
        margin-top: 0;
        width: 100%;
    }
    .form-container {
        color: $text-color;
        border-radius: 7px;
        background-color: #f5f5fb;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
        padding: 30px 20px;
        margin-top: 30px;
        margin-bottom: 30px;
        @include transition(all 0.3s ease-in-out);

        .row {
            align-items: flex-end;
            margin-left: -15px;
            margin-right: -15px;
        }
        .btn-label {
            text-align: center;
            position: relative;
            height: 0;
            top: -10px;
        }
        h3 {
            color: $text-color;
            font-weight: 600;
            font-size: 2.5em;
            text-align: left;
        }
        .form-privacy {
            color: $text-color;
            font-size: 0.7em;
            margin-bottom: 0;
        }
        .marketo-form .mktoForm .mktoFormRow:nth-of-type(-n+2) {
            width: 100%;
        }
        .marketo-form .mktoForm .mktoFormRow:nth-of-type(2) input[type=text], .marketo-form .mktoForm .mktoFormRow:nth-of-type(2) input[type=url], .marketo-form .mktoForm .mktoFormRow:nth-of-type(2) input[type=email], .marketo-form .mktoForm .mktoFormRow:nth-of-type(2) input[type=tel], .marketo-form .mktoForm .mktoFormRow:nth-of-type(2) input[type=number], .marketo-form .mktoForm .mktoFormRow:nth-of-type(2) input[type=date], .marketo-form .mktoForm .mktoFormRow:nth-of-type(2) select.mktoField, .marketo-form .mktoForm .mktoFormRow:nth-of-type(2) textarea.mktoField {
            width: 100% !important;
            margin-left: 0;
        }
        .marketo-form .mktoForm .mktoFormRow:first-of-type input[type=text], .marketo-form .mktoForm .mktoFormRow:first-of-type input[type=url], .marketo-form .mktoForm .mktoFormRow:first-of-type input[type=email], .marketo-form .mktoForm .mktoFormRow:first-of-type input[type=tel], .marketo-form .mktoForm .mktoFormRow:first-of-type input[type=number], .marketo-form .mktoForm .mktoFormRow:first-of-type input[type=date], .marketo-form .mktoForm .mktoFormRow:first-of-type select.mktoField, .marketo-form .mktoForm .mktoFormRow:first-of-type textarea.mktoField {
            width: 100% !important;
        }
        .marketo-form .mktoForm input[type=text], .marketo-form .mktoForm input[type=url], .marketo-form .mktoForm input[type=email], .marketo-form .mktoForm input[type=tel], .marketo-form .mktoForm input[type=number], .marketo-form .mktoForm input[type=date], .marketo-form .mktoForm select.mktoField, .marketo-form .mktoForm textarea.mktoField {
            border: solid 1px #e3e3e3;
            margin-bottom: 3px;
        }
    }

}

.form-hero-2 {
    height: 670px;
    background: #29a7df;
    background-image: url(../img/hero-bkg.jpg);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#29a7df), to(#29a7df));
    background-image: -webkit-linear-gradient(right, rgba(41, 167, 223, 0.9), rgba(41, 167, 223, 0.7)), url(../img/hero-bkg.jpg);
    background-image: linear-gradient(to left, rgba(41, 167, 223, 0.9), rgba(41, 167, 223, 0.7)), url(../img/hero-bkg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .col-sm-1 {
        padding: 0;
        text-align: center;
    }
    .or-separator {
        display: block;
        margin-bottom: 9px;
    }
    .form-container {
        color: white;
        background-color: transparent;
        box-shadow: none;
        &.expanded {
            color: $text-color;
            background-color: #f5f5fb;
            box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
            @include transition(all 0.3s ease-in-out);
            .btn-label {
                color: $blue;
            }
            .btn-white {
                color: white;
                background-color: $blue;
            }
            .btn-ghost {
                color: $blue;
                border-color: $blue;
                &:hover,
                &:focus,
                &:active {
                    color: white;
                    background-color: $blue;
                }
            }
        }
    }
}
